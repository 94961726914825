import { Tooltip } from "@material-ui/core"

import SearchFilter from "src/components/Filter/SearchFilter"
import { BatteryAndChargeStatusIcon } from "src/components/Homes/DeviceDetails/BatteryAndChargeStatusIcon"
import { OnlineStatus } from "src/components/Homes/DeviceDetails/OnlineStatus"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  DescriptionWrapper,
  Ellipsis,
  FilterWrapper,
  IdWrapper,
  ParadiseLayout,
  PillsWrapper,
  StatusWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useSortParam } from "src/components/Paradise/useTableColumns/useSortParam"
import {
  TableColumn,
  useTableColumns,
} from "src/components/Paradise/useTableColumns/useTableColumns"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import {
  useFetchParadiseDevices,
  useFetchParadiseDevicesCount,
} from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import {
  IParadiseDevice,
  IParadiseDeviceListFilter,
} from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { colors } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import InfoIcon from "src/ui/icons/info.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { WifiIcon } from "src/ui/WifiIcon/WifiIcon"
import { formatDate } from "src/utils/l10n"

const tableColumns: TableColumn<IParadiseDevice>[] = [
  {
    label: "Device id",
    value: "device_id",
    disabled: true,
    enableSort: true,
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (data) => (
      <IdWrapper>
        <InternalLink
          to={Routes.ParadiseDevice.location(data.device_id).pathname}
        >
          {data.device_id}
        </InternalLink>
        {!data.active && (
          <Tooltip title="Inactive">
            <InfoIcon width={12} fill={colors.systemEmergencyForeground} />
          </Tooltip>
        )}
      </IdWrapper>
    ),
  },
  {
    label: "Device mac",
    value: "device_mac",
    disabled: true,
    enableSort: true,
    columnWidth: "min-content",
    render: (data) => <div>{data.device_mac}</div>,
  },
  {
    label: "Description",
    value: "description",
    columnWidth: 300,
    disabled: true,
    enableSort: true,
    render: (data) => <Ellipsis>{data.description}</Ellipsis>,
  },
  {
    label: "Owner",
    value: "owner",
    enableSort: true,
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (data) => (
      <InternalLink to={Routes.ParadiseUser.location(data.owner).pathname}>
        {data.owner}
      </InternalLink>
    ),
  },
  {
    label: "Tags",
    value: "tags",
    columnWidth: "min-content",
    render: (data) => (
      <div>
        <PillsWrapper $noWrap>
          {data.tags.map((tag, i) => (
            <MBadge size="small" color="neutral" key={tag + i}>
              {tag}
            </MBadge>
          ))}
        </PillsWrapper>
      </div>
    ),
  },
  {
    label: "Last updated at",
    enableSort: true,
    value: "last_updated_at",
    columnWidth: "min-content",
    render: (data) => (
      <div>
        {data.last_heard_from_at
          ? formatDate({ date: data.last_heard_from_at, timezone: "utc" })
          : "-"}
      </div>
    ),
  },
  {
    label: "Status",
    enableSort: true,
    value: "status",
    columnWidth: "min-content",
    render: (data) => (
      <div>
        <OnlineStatus online={!data.offline} />
      </div>
    ),
  },
  {
    label: "Battery",
    enableSort: true,
    value: "battery",
    columnWidth: "min-content",
    render: (data) => (
      <StatusWrapper>
        <BatteryAndChargeStatusIcon
          percent={data.battery?.percent}
          offline={data.offline}
          chargeStatus={data.charge_status}
        />
        {data.battery?.voltage && (
          <MText variant="nano">{data.battery?.voltage.toFixed(2)}V</MText>
        )}
      </StatusWrapper>
    ),
  },
  {
    label: "Signal",
    value: "signal",
    columnWidth: "min-content",
    render: (data) => (
      <StatusWrapper>
        <WifiIcon quality={data.wlan?.signal_quality} offline={data.offline} />
        {data.wlan?.rssi && <MText variant="nano">{data.wlan?.rssi}</MText>}
      </StatusWrapper>
    ),
  },
  {
    label: "Firmware",
    enableSort: true,
    value: "firmware",
    columnWidth: "min-content",
    render: (data) => <div>{data.firmware.installed}</div>,
  },
  {
    label: "Hardware",
    enableSort: true,
    value: "hardware",
    columnWidth: "min-content",
    render: (data) => (
      <div>{getDeviceHardwareType(data.hardware_version).type}</div>
    ),
  },
]

const LIMIT = 50

export function ParadiseDevices() {
  const { navigate } = useRouter()

  const { offset, limit, setOffset } = useUrlPager({
    initialLimit: LIMIT,
  })
  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [
      {
        key: "device_id",
        type: "string",
      },
      {
        key: "description",
        type: "string",
      },
      {
        key: "device_mac",
        type: "string",
      },
    ],
  })
  const { sortValue, setSortValue } = useSortParam()

  // If the user has filtered we want to show inactive devices aswell, otherwise just active devices is fetched
  const hasFiltered = Object.values(filter).some((f) => !!f)

  const fetchedDevices = useFetchParadiseDevices({
    filter: {
      limit,
      offset,
      device_id: filter.device_id || undefined,
      description: filter.description || undefined,
      device_mac: filter.device_mac || undefined,
      active: hasFiltered ? undefined : true,
      sort:
        (sortValue?.id as IParadiseDeviceListFilter["sort"]) || "device_mac",
      sort_by: sortValue?.order || "asc",
    },
    options: {
      keepPreviousData: true,
    },
  })

  const fetchDevicesCount = useFetchParadiseDevicesCount()

  const {
    headerElements,
    rows,
    interactiveColumns,
    setColumnVisibility,
    interactiveVisibleColumns,
    templateColumns,
  } = useTableColumns<IParadiseDevice>({
    columns: tableColumns,
    data: fetchedDevices.data?.devices,
    sort: sortValue,
    onSortChange: (newSort) => {
      setSortValue(newSort)
    },
    options: { localStorageKey: "minut.paradise.devices.table" },
  })

  return (
    <ParadiseLayout>
      <Titlebar
        title="Devices"
        description={
          !fetchDevicesCount.isLoading ? (
            <DescriptionWrapper>
              <MText variant="body">
                Online: {fetchDevicesCount.data?.online} |{" "}
              </MText>
              <MText variant="body">
                Offline: {fetchDevicesCount.data?.offline}
              </MText>
            </DescriptionWrapper>
          ) : (
            <MSkeleton width="8ch" />
          )
        }
      />
      <TopWrapper>
        <FilterWrapper>
          <SearchFilter
            initialValue={filter.device_id ?? undefined}
            label="Search by id"
            onChange={(value) => {
              setFilter("device_id", value)
              setOffset(0)
            }}
            fullWidth
          />
          <SearchFilter
            initialValue={filter.description ?? undefined}
            label="Search by description"
            onChange={(value) => {
              setFilter("description", value)
              setOffset(0)
            }}
            fullWidth
          />
          <SearchFilter
            initialValue={filter.device_mac ?? undefined}
            label="Search by mac"
            onChange={(value) => {
              setFilter("device_mac", value)
              setOffset(0)
            }}
            fullWidth
          />
        </FilterWrapper>
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            setColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (fetchedDevices.data?.devices) {
            navigate(
              Routes.ParadiseDevice.location(
                fetchedDevices.data.devices[index].device_id
              )
            )
          }
        }}
        error={{
          hasError: fetchedDevices.isError,
          title: fetchedDevices.error?.message,
        }}
      />
      <ParadisePager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedDevices.data?.total_count}
      />
    </ParadiseLayout>
  )
}
